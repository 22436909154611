import React, { useLayoutEffect, useRef, useState } from 'react';
import { JoykitColor, TypographyMappingDesktopXlVariant } from '@components/themes';
import { Box } from '@components/Box';
import { Text } from '@components/typography';
import { AnimatedPlusMinusIcon } from './AnimatedPlusMinusIcon';
import { Summary, Spacing, Details, StyledAnswerText, StyledTitle, StyledAnimatedIconWrapper } from './AccordionItem.styles';
import { FaqVariants } from '@components/FaqList/FaqList';

interface AccordionItemProps {
  id: string;
  title: string | React.ReactElement[];
  bodyTitle: string | React.ReactElement[];
  color: JoykitColor;
  bodyColor?: JoykitColor;
  isExpanded: boolean;
  onClick: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLDivElement>) => void;
  iconColor?: JoykitColor;
  titleVariant?: TypographyMappingDesktopXlVariant;
  variant?: FaqVariants;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  id,
  title,
  bodyTitle,
  color,
  bodyColor = color,
  iconColor = 'blue5',
  isExpanded,
  onClick,
  titleVariant = 'calloutHed32',
  variant = 'default',
}) => {
  const [height, setHeight] = useState('0px');
  const el = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (el && el.current) {
      setHeight('auto');
    }
  }, [setHeight]);

  return (
    <Box itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <Summary role="heading" aria-level={3}>
        <Spacing
          data-id={id}
          id={id}
          role="button"
          aria-expanded={isExpanded}
          aria-controls={`${id}-content`}
          onClick={onClick}
          onKeyPress={onClick}
          tabIndex={0}
          variant={variant}
        >
          <StyledAnimatedIconWrapper variant={variant}>
            <AnimatedPlusMinusIcon isExpanded={isExpanded} iconColor={iconColor} />
          </StyledAnimatedIconWrapper>
          <StyledTitle tagName="p" variant={titleVariant} color={color} accordionVariant={variant}>
            <span itemProp="name">{title}</span>
          </StyledTitle>
        </Spacing>
      </Summary>
      <Details isExpanded={isExpanded} id={`${id}-content`} aria-labelledby={id} ref={el} height={height}>
        <Spacing variant={variant} itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div style={{ marginLeft: 24 }}></div>
          <StyledAnswerText accordionVariant={variant} tagName="p" variant="dek20" color={bodyColor} textAlign="left">
            <span itemProp="text">{bodyTitle}</span>
          </StyledAnswerText>
        </Spacing>
      </Details>
    </Box>
  );
};
