import { JoykitColor } from '@components/themes';
import { themeColor } from '@utils/styledTheme.util';
import React from 'react';
import { Spring, animated } from 'react-spring';
import { useTheme } from 'styled-components';

const path = {
  med:
    'M22.0833 11.0742H12.9167V1.91667C12.9167 1.41008 12.5066 1 12 1C11.4934 1 11.0833 1.41008 11.0833 1.91667V11.0742H1.91667C1.41008 11.0742 1 11.4934 1 12C1 12.5066 1.41008 12.9076 1.91667 12.9076H11.0833V22.0833C11.0833 22.5899 11.4934 23 12 23C12.5066 23 12.9167 22.5899 12.9167 22.0833V12.9076H22.0833C22.5899 12.9076 23 12.5066 23 12C23 11.4934 22.5899 11.0742 22.0833 11.0742Z',
  max:
    'M22.0833 11.0746H13.0069H12.9167L12 11.0742L11.0833 11.0746H11H1.91667C1.41008 11.0746 1 11.4938 1 12.0004C1 12.507 1.41008 12.908 1.91667 12.908H11H11.0833H12H12.9167H13.0069H22.0833C22.5899 12.908 23 12.507 23 12.0004C23 11.4938 22.5899 11.0746 22.0833 11.0746Z',
};

interface SpringProps {
  immediate: boolean;
  reset: boolean;
}

// TODO: @olivia we should fix the any usage below
export const AnimatedPlusMinusIcon: React.FC<{ isExpanded: boolean; iconColor?: JoykitColor }> = ({ isExpanded, iconColor = 'blue5' }) => {
  const theme = useTheme();
  const color = themeColor(iconColor)({ theme });

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" preserveAspectRatio="none" aria-label="animated-plus-minus">
      <path fill={color} fillRule="evenodd" clipRule="evenodd" d={path.max}></path>
      <Spring native to={{ opacity: isExpanded ? 0 : 1 }}>
        {({ immediate, reset, ...props }: SpringProps) => (
          <animated.path d={path.med} {...props} fill={color} fillRule="evenodd" clipRule="evenodd" />
        )}
      </Spring>
    </svg>
  );
};
