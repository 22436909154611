import React, { useState } from 'react';
import { QuestionNodeType } from './FaqList';

const analytics = require('../../../static/js/joy');

export const useFaqListController = (questionNodes: ReadonlyArray<QuestionNodeType>, sendTelemetry: boolean) => {
  const defaultState = questionNodes.map((node, i) => ({
    id: `${node.section}-${i}`,
    question: node.question,
    answer: node.answer,
    isExpanded: false,
  }));

  const [faqState, setFaqState] = useState(defaultState);
  const [lastInteractedFaqId, setlastInteractedFaqId] = useState<string | null>(null);

  const onClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLDivElement>) => {
    const faqId = e.currentTarget.dataset.id;
    if (!faqId) return;
    const newState = faqState.map(faq => {
      let updatedFaq = faq;
      if (faq.id === faqId) {
        updatedFaq = {
          ...faq,
          isExpanded: !faq.isExpanded,
        };
        if (sendTelemetry) {
          analytics.segment.sendTelemetryEvent('faqExpanded', {
            value: faq.question,
          });
        }
      } else if (faq.id === lastInteractedFaqId) {
        updatedFaq = {
          ...faq,
          isExpanded: false,
        };
      }
      return updatedFaq;
    });
    setlastInteractedFaqId(faqId);
    setFaqState(newState);
  };

  return {
    faqState,
    lastInteractedFaqId,
    onClick,
  };
};
