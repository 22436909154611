import styled, { css } from 'styled-components';
import { Box } from '@components/Box';
import { Text } from '@components/typography';
import { themeMediaQuery, themeSpace } from '@utils/styledTheme.util';
import { FaqVariants } from '@components/FaqList/FaqList';

const variants = {
  secondary: {
    accordionTitle: css`
      margin-bottom: ${themeSpace(6)};
    `,
    accordionBody: css`
      padding-bottom: ${themeSpace(6)};
    `,
    spacingDiv: css`
      gap: ${themeSpace(5)};
      margin: 0;
      ${themeMediaQuery('md')} {
        gap: ${themeSpace(6)};
        margin: 0 ${themeSpace(6)};
      }
    `,
    animatedIcon: css`
      font-size: ${themeSpace(5)};
    `,
  },
  default: {
    accordionTitle: {
      marginBottom: '28px',
    },
    accordionBody: {},
    spacingDiv: css`
      gap: ${themeSpace(7)};
      margin: 0px ${themeSpace(8)};
      ${themeMediaQuery('sm')} {
        gap: '4.5rem';
        margin: 0px ${themeSpace(6)};
      }
    `,
    animatedIcon: css`
      font-size: ${themeSpace(7)};
    `,
  },
};

export const Summary = styled(Box)<{ role: string }>``;

export const Spacing = styled.div<{ variant: FaqVariants }>`
  display: flex;
  cursor: pointer;
  ${props => variants[props.variant].spacingDiv}
`;

interface ContentProps {
  isExpanded: boolean;
  ref: React.Ref<HTMLDivElement>;
  height: string;
}

export const Details = styled.div<ContentProps>`
  height: ${props => (props.isExpanded ? props.height : '0px')};
  overflow: ${props => (props.isExpanded ? 'auto' : 'hidden')};
  transition: height 250ms ease-in 50ms;
  a {
    display: ${props => (props.isExpanded ? `inline-block` : 'none')};
  }
`;

export const StyledAnswerText = styled(Text)<{ accordionVariant: FaqVariants }>`
  .faq-link {
    text-decoration: underline;
    cursor: pointer;
    display: initial;
  }
  ${props => variants[props.accordionVariant].accordionBody}
`;

export const StyledTitle = styled(Text)<{ accordionVariant: FaqVariants }>`
  text-align: 'left';
  ${props => variants[props.accordionVariant].accordionTitle}
`;

export const StyledAnimatedIconWrapper = styled.div<{ variant: FaqVariants }>`
  line-height: '42px';
  ${props => variants[props.variant].animatedIcon};
`;
