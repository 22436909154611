import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Columns } from '@components/Columns';
import { JoykitColor, TypographyMappingDesktopXlVariant } from '@components/themes';
import { SpacingStack } from '@components/SpacingStack';
import { TextPairing } from '@components/TextPairing';
import { useFaqListController } from './FaqList.controller';
import { AccordionItem } from './components/AccordionItem';
import { Box } from '@components/Box';
import { Flex } from '@components/Flex';
import { CTALink } from '@components/CTALink';
import { centeredFaqSectionData } from '@data-ts/shared/sections/centeredFaqSection.data';
import styled, { css } from 'styled-components';
import { themeColor } from '@utils/styledTheme.util';
export interface QuestionNodeType
  extends Readonly<{
    section: string;
    question: string;
    answer: string;
  }> {}

export type FaqVariants = 'default' | 'secondary';

export interface FaqProps
  extends Readonly<{
    questionNodes: ReadonlyArray<QuestionNodeType>;
    color: JoykitColor;
    bodyColor?: JoykitColor;
    accordion?: boolean;
    isLinkHidden?: boolean;
    iconColor?: JoykitColor;
    titleVariant?: TypographyMappingDesktopXlVariant;
    variant?: FaqVariants;
    sendTelemetry?: boolean;
  }> {}

const StyledAnswerTextPairing = styled(TextPairing)`
  .faq-link {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const variants = {
  secondary: css`
    border: 1px solid ${themeColor('mono4')};
    border-left: 0;
    border-right: 0;
    padding-top: 20px;
    > :not(:last-child) {
      margin-bottom: 20px;
      border-bottom: 1px solid ${themeColor('mono4')};
    }
  `,
  default: css``,
};

const StyledSpacingStack = styled(SpacingStack)<{ variant: 'default' | 'secondary' }>`
  ${props => variants[props.variant]}
`;

export const FaqList: React.FC<FaqProps> = ({
  questionNodes,
  color,
  bodyColor = color,
  accordion = false,
  iconColor = 'blue5',
  isLinkHidden = false,
  titleVariant = 'calloutHed32',
  variant = 'default',
  sendTelemetry = false,
}) => {
  const { faqState, onClick } = useFaqListController(questionNodes, sendTelemetry);
  return (
    <Box {...(accordion && { itemType: 'https://schema.org/FAQPage', itemScope: true })}>
      <StyledSpacingStack variant={variant} spacing="80px">
        {faqState.map(faq => {
          if (accordion) {
            return (
              <AccordionItem
                id={faq.id}
                title={ReactHtmlParser(faq.question)}
                bodyTitle={ReactHtmlParser(faq.answer)}
                color={color}
                bodyColor={bodyColor}
                isExpanded={faq.isExpanded}
                key={faq.id}
                onClick={onClick}
                iconColor={iconColor}
                titleVariant={titleVariant}
                variant={variant}
              />
            );
          } else
            return (
              <Columns>
                <Columns.Column>
                  <span itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                    <StyledAnswerTextPairing
                      key={faq.id}
                      title={<span itemProp="name">{ReactHtmlParser(faq.question)}</span>}
                      titleVariant="calloutHed32"
                      titleColor={color}
                      titleTagName="h3"
                      body={
                        <span itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                          <span itemProp="text">{ReactHtmlParser(faq.answer)}</span>
                        </span>
                      }
                      bodyVariant="dek20"
                      bodyColor={bodyColor}
                      alignment="left"
                    />
                  </span>
                </Columns.Column>
              </Columns>
            );
        })}
        {!isLinkHidden && (
          <Flex justifyContent="flex-end" marginRight={[9]} className="read-more">
            <CTALink id="faqReadMore" href="/faq">
              {centeredFaqSectionData.readMore}
            </CTALink>
          </Flex>
        )}
      </StyledSpacingStack>
    </Box>
  );
};
